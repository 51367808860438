declare global {
    interface Window {
        TMap: any,
        initMap: any
    }
}

const map = new Promise((resolve, reject) => {
    if (window.TMap) {
        resolve(window.TMap)
        return
    }
    window.initMap = () => {
        resolve(window.TMap)
    }
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://map.qq.com/api/gljs?v=1.exp&key=OB4BZ-D4W3U-B7VVO-4PJWW-6TKDJ-WPB77&callback=initMap";
    script.onerror = reject
    document.head.appendChild(script);
})

export default map