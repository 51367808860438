import { useImperativeHandle, forwardRef, useState, useEffect, useLocation, post, get } from '@/common'
import { Modal, message } from 'antd';

function Main(props: any, ref: any) {
    const location = useLocation()
    const pathname = props.apiUrl || location.pathname
    const [visible, setVisible] = useState<any>();
    const form = props.children.props.form
    const list = props.nref.list.current
    const [action, setAction] = useState('add');
    const [id, setId] = useState<string>();
    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('操作');
    const width = props.width || 520
    const id_field = props.idField || 'id'
    // 暴露方法
    useImperativeHandle(ref, () => ({
        add: async () => {
            form.resetFields()
            setVisible(true)
            setAction('add')
            setTitle(`添加${props.name}`)
        },
        edit: async (id: string) => {
            form.resetFields()
            setVisible(true)
            setAction('edit')
            setTitle(`修改${props.name}`)
            return getDetail(id)
        },
        editPw: async (id: string) => {
            form.resetFields()
            setVisible(true)
            setAction('edit_pw')
            setTitle(`修改密码`)
            setId(id)
        },
    }));
    const getDetail = async (id: string) => {
        return get(`${pathname}/detail/${id}`).then(data => {
            if (props.getDetailSuccces && typeof (props.getDetailSuccces) == "function") props.getDetailSuccces(data.data)
            form.setFieldsValue(data.data)
            setId(data.data[id_field])
            return data.data
        })
    }
    useEffect(() => {
        setVisible(props.visible)
    }, [props.visible])
    const ok = () => {
        if (loading) return
        setLoading(true)
        // 验证表单
        // form.validateFields().then((data: any) => {
        form.validateFields().then(() => {
            let data = form.getFieldsValue()
            // 提交前处理
            if (props.submitBefore && typeof (props.submitBefore) == "function") if (props.submitBefore(data) === false) {
                setLoading(false)
                return false
            }
            // // 追加字段
            // if (props.formName) {
            //     props.formName.forEach((e: any) => {
            //         data[e] = form.getFieldValue(e)
            //     });
            // }
            let url = `${pathname}/${action}`
            if (['edit', 'edit_pw'].indexOf(action) > -1) url += `/${id}`
            post(url, data).then((data) => {
                if (data.code) return
                setVisible(false)
                // 更新列表
                list.getList()
                if (props.submitSuccces && typeof (props.submitSuccces) == "function") props.submitSuccces(data.data)
            })
                .finally(() => {
                    setLoading(false)
                })
        })
            .catch(() => {
                setLoading(false)
            })
    }
    const cancel = () => {
        setVisible(false)
    }
    return (
        <Modal title={title} visible={visible} onOk={ok} onCancel={cancel} confirmLoading={loading}
            maskClosable={false}
            width={width}
            forceRender
        >
            {props.children}
        </Modal>
    )
}

export default forwardRef(Main)