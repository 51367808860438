import { MyIcon, useLocation, config } from '@/common'
import { Breadcrumb } from 'antd';
import '../assets/style/breadcrumb.scss';

function Main() {
    let pathname = useLocation().pathname.split('/')
    // 从本地获取模块信息
    let breadcrumb: any = [], dom_breadcrumb
    try {
        let modules = JSON.parse(localStorage.getItem('module') || '[]')[config.app.id]

        if (modules.length) {
            modules.forEach((e: any) => {
                if (pathname[1] === e.path) {
                    breadcrumb.push(
                        <Breadcrumb.Item key={e.id} href={e.url}>
                            <span><MyIcon type={`icon-${e.ico}`} /> {e.name}</span>
                        </Breadcrumb.Item>
                    )
                    if (e.children) {
                        e.children.forEach((e_1: any) => {
                            if (pathname[2] === e_1.path) {
                                // 面包屑
                                breadcrumb.push(
                                    <Breadcrumb.Item key={e_1.id} href={e_1.url}>
                                        <span><MyIcon type={`icon-${e_1.ico}`} /> {e_1.name}</span>
                                    </Breadcrumb.Item>
                                )
                                if (e_1.children) {
                                    e_1.children.forEach((e_2: any) => {
                                        if (pathname[3] === e_2.path) {
                                            // 面包屑
                                            breadcrumb.push(
                                                <Breadcrumb.Item key={e_2.id} href={e_2.url}>
                                                    {e_2.name}
                                                </Breadcrumb.Item>
                                            )
                                        }
                                    })
                                }
                            }
                        })
                    }
                }
            });
            dom_breadcrumb = <Breadcrumb>{breadcrumb}</Breadcrumb>
        }
    } catch (error) {

    }
    return (
        <div className="breadcrumb">
            <div className="left">
                {dom_breadcrumb}
                {/* <Space className="icos">
                <ReloadOutlined title="刷新" onClick={() => {this.props.history.push()}}/>
                {React.createElement(this.state.magnify_status ? FullscreenExitOutlined : FullscreenOutlined, {
                    title: this.state.magnify_status ? '缩小' : '放大',
                    onClick: this.magnify,
                })}
                </Space> */}
            </div>
            <div className="right">
            </div>
        </div>
    )
}

export default Main