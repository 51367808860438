import { useLocation, post } from '@/common'
import { Popconfirm, Button } from 'antd';

function Main(props: any) {
  const location = useLocation()
  const pathname = props.apiUrl || location.pathname
  const text = props.text || "删除"
  const name = props.name || "记录"
  const del = () => {
    post(`${pathname}/del`, [props.id]).then(data => {
      if(data.code)return
      props.nref.list.current.getList()
      if (props.success) props.success(data.data)
    })
  }
  return (
    <Popconfirm
      title={`是否${text}该${name}？`}
      onConfirm={del}
    >
      <Button type="primary" size="small" danger>{text}</Button>
    </Popconfirm>
  )
}
export default Main