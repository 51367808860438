import {Func,post,useEffect,useState} from '@/common';
import {Button,message} from 'antd';

var
interval:NodeJS.Timer,
number:number,
phone:string

function Main(props:any){
    const [disabled, setDisabled] = useState(false);
    const [text, setText] = useState("获取验证码");
    useEffect(() => {
        if(props.text !== undefined)setText(props.text)
        number = Number(sessionStorage.getItem('msg_captcha'))
        if(number > 0){
            setDisabled(true)
            setText(number.toString() + 's')
            clearInterval(interval)
            runInterval()
        }else{
            number = 60
        }
        return () => {
            clearInterval(interval)
        }
    },[])
    const getCaptcha = () => {
        if(disabled)return
        phone = props.form.getFieldValue().phone;
        if(!phone){
            message.error('请输入手机号')
            return
        }
        if(!Func.testPhone(phone)){
            message.error('请输入正确手机号')
            return
        }
        // 开启倒计时发送验证码
        runInterval()
        post('/opens/sendMsg/' + props.type,{phone})
    }
    const runInterval = () => {
        setDisabled(true)
        setText(number.toString() + 's')
        interval = setInterval(function(){
            number --
            sessionStorage.setItem('msg_captcha',number.toString())
            if(number <= 0){
                setDisabled(false)
                setText(text)
                number = 60
                clearInterval(interval)
                return
            }
            setText(number.toString() + 's')
        },1000)
    }
    return(<Button onClick={getCaptcha} disabled={disabled}>{text}</Button>)
}

export default Main