import { useState, useImperativeHandle, get, useLocation ,forwardRef} from '@/common'
import { Drawer } from 'antd';

function Main(props: any, ref: any) {
    const location = useLocation()
    const pathname = props.apiUrl || location.pathname
    const [visible, setVisible] = useState(false);
    // const title = props.title || "详情"
    // 暴露方法
    useImperativeHandle(ref, () => ({
        show: async (id: number) => {
            setVisible(true)
            get(`${pathname}/detail/${id}`).then(data => {
                if (props.succces && typeof (props.succces) == "function")props.succces(data.data)
            })
        },
    }));
    return (
        <Drawer
            title={`${props.name}详情`}
            visible={visible}
            placement={'left'}
            onClose={() => { setVisible(false) }}
            width="780"
        >
            {props.children}
        </Drawer>
    )
}
export default forwardRef(Main)