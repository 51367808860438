import { get, useState, useMemo, useRef, Func } from '@/common'
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';

async function fetchOptions(url: string, phone: string) {
    if (Func.testPhone(phone)) {
        return get(`${url}/${phone}`,).then((data) => {
            if (!data.code) {
                data = data.data
                return [{
                    label: `${data.phone}${data.name ? `（${data.name}）` : ''}`,
                    value: data.id,
                }]
            }
        })
    }
}

function Main(props: any) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
    const debounceTimeout = 800

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value: any) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            // setOptions([]);
            setFetching(true);
            fetchOptions(props.url, value).then((newOptions: any) => {
                if (newOptions !== undefined) {
                    if (fetchId !== fetchRef.current) {
                        return;
                    }
                    setOptions(newOptions);
                    setFetching(false);
                }
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
    const onChange = (arr:any) => {
        let members:any = [];
        arr.forEach((e:any) => {
            members.push(e.value)
        });
        props.onChange(members);
    }
    return (
        <Select
            mode="multiple"
            labelInValue
            filterOption={false}
            onSearch={debounceFetcher}
            notFoundContent={fetching ? <Spin size="small" /> : null}
            options={options}
            {...props}
            onChange={onChange}
        />
    )
}

export default Main