import { post } from '@/common'
import { message } from 'antd';

class Main {
    // 切换组织
    public static switchOrganization = (id: number) => {
        const member = JSON.parse(localStorage.getItem('member') || '{}')
        if (id === member.organization_id_now) {
            message.warn("当前为该公司")
            return
        }
        post('/authority/member/switchOrganization', { 'id': id }).then(data => {
            if(data.code)return
            data = data.data
            localStorage.setItem('member', JSON.stringify(data.member))
            localStorage.setItem('module', JSON.stringify(data.module))
            // 刷新页面
            setTimeout(() => { window.location.reload() }, 100)
        })
    }
    public static getConstants = () => {
        return JSON.parse(localStorage.getItem('constants') || '{}')
    }
}
export default Main