import { Link, post, config, MsgCaptcha } from '@/common';
import { Form, Input, Button } from 'antd';
import {
    MobileOutlined,
    KeyOutlined,
    SafetyOutlined
} from '@ant-design/icons';
import '@/assets/style/entrance.scss';
import Copyright from '@/components/Copyright';

function Main(props: any) {
    const [form] = Form.useForm();
    const onFinish = (data: any) => {
        post('/findPw', data).then(data => {
            if (data.code) return
            props.history.push('/login')
        })
    }
    return (
        <div className="entrance">
            <Form
                className="form"
                onFinish={onFinish}
                form={form}
            >
                <img className="logo" src="/images/logo.png" alt="LOGO" />
                <div className="name">{config.app.name}</div>
                <div className="title">找回密码</div>
                <Form.Item
                    name="phone"
                    rules={[{ required: true, message: '手机号为必填' }]}
                >
                    <Input size="large" placeholder="请输入手机号" prefix={<MobileOutlined />} />
                </Form.Item>
                <Form.Item
                    className="captcha"
                >
                    <Form.Item
                        noStyle
                        name="captcha"
                        rules={[{ required: true, message: '验证码为必填' }]}
                    >
                        <Input size="large" placeholder="请输入验证码" maxLength={4} prefix={<SafetyOutlined />} />
                    </Form.Item>
                    <MsgCaptcha form={form} type="findPw" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        { required: true, message: '新密码为必填' },
                        { pattern: new RegExp('^[a-zA-Z0-9_]{8,32}$', "gim"), message: '密码格式有误' }
                    ]}
                >
                    <Input.Password size="large" placeholder="设置新密码（8-20个字，包含字母和数字）" prefix={<KeyOutlined />} />
                </Form.Item>
                <Button size="large" type="primary" htmlType="submit" block>
                    提 交
                </Button>
                <div className="register">
                    <span></span>
                    <span>已有账号？<Link to="/login">登录</Link></span>
                </div>
            </Form>
            <Copyright />
        </div>
    )
}

export default Main