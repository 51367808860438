import { useEffect, useRef, config, useState, Func } from '@/common'
import E from 'wangeditor'

function Main(props: any) {
    const ref = useRef<any>()
    const [editor, setEditor] = useState<any>()

    useEffect(() => {
        setEditor(new E(ref.current))
    }, [])

    useEffect(() => {
        if (editor) {
            editor.config.uploadImgServer = config.api.url._system + '/resource/upload'
            editor.config.onchange = (html: any) => {
                props.onChange(html)
            }
            editor.config.uploadFileName = 'file'
            editor.config.uploadImgHooks = {
                customInsert: (insertImgFn: any, result: any) => {
                    if (!result.code) {
                        insertImgFn(Func.getRealUrl(result.data))
                    }
                }
            }
            editor.create()
            return () => {
                if (editor) {
                    editor.destroy()
                }
            }
        }
    }, [editor])

    useEffect(() => {
        if (editor) {
            editor.txt.html(props.value)
        }
    }, [props.value])

    return (
        <div ref={ref}></div>
    )
}

export default Main